import React, { ReactNode, useState } from "react"
import clsx from "clsx"
import ClickAwayListener from "@material-ui/core/ClickAwayListener"
import Tooltip from "@material-ui/core/Tooltip"
import { Zoom } from "@material-ui/core"
import Container from "@ecom/ui/components/Container"
import close_icon from "../../images/icons/icon_closeV2.svg"

import {
  Steps,
  DEFAULT_STEPS,
  STEPS_PKW,
  STEPS_REFIN,
  STEPS_HALVA,
  STEPS_REDISIGN_BEZPROCENTOV,
  STEPS_PKW_REDISIGN,
  STEPS_STICKER_PAY,
  STEPS_SHUBA,
  STEPS_INVITE_FRIEND,
  STEPS_NEWYEAR,
  STEPS_SHAPKA,
  STEPS_KIBERPONEDELNIK,
  STEPS_GENDER_ACTION,
  STEPS_PRODUCTS_CATEGORIES,
  STEPS_PROMOPAGE,
  STEPS_NEDELI_UMNUX_RASSROCHEK,
  STEPS_NEDELI_UMNUX_RASSROCHEK_MEDIA,
  STEPS_LENTA,
  STEPS_REFIN_KREDITNOY_KARTY,
  STEPS_PKW_OZON,
  STEPS_PKW_AVIASALES,
  STEPS_ZOON,
  STEPS_PKW_BONUS_1500,
  STEPS_100_CASHBACK,
  STEPS_BTS_AUTO,
  STEPS_AUTO_PROMO,
  STEPS_VK_EDUCATION,
  STEPS_HIPPO,
  STEPS_BLACK_FRIDAY_HALVA,
  STEPS_BLACK_FRIDAY,
  STEPS_PROCENTY_VERNET_MAGAZIN,
  STEPS_NEWYEAR_MEDIA,
  STEPS_NEWYEAR_HALVA,
  STEPS_RASSROCHKA_S_HALVOI,
  STEPS_MEDIA,
} from "./helpers"

import * as styles from "./howInstallmentWorks.module.scss"

type Props = {
  variant?:
    | "pkw"
    | "standard"
    | "refin"
    | "refinKreditnoyKarty"
    | "halva"
    | "redisignBezProcentov"
    | "pkwRedesign"
    | "promopage"
    | "stickerPay"
    | "shuba"
    | "inviteFriend"
    | "newYear"
    | "shapka"
    | "kiberponedelnik"
    | "productsCategories"
    | "genderAction"
    | "nedeliUmnuxRassrochek"
    | "nedeliUmnuxRassrochekMedia"
    | "lenta"
    | "pkwOzon"
    | "pkwAviasales"
    | "zoon"
    | "bonus1500"
    | "cashback100"
    | "btsAuto"
    | "autoPromo"
    | "vkEducation"
    | "hippo"
    | "blackFridayHalva"
    | "blackFriday"
    | "procentyVernetMagazin"
    | "newYearMedia"
    | "newYearHalva"
    | "rassrochkaSHalvoi"
    | "media"

  orderNum?: string
  headTitle?: ReactNode
  pkwOther?: boolean
  secondToolTip?: string
}

export const HowInstallmentWorks = ({
  variant = "standard",
  orderNum,
  headTitle = "Как работает рассрочка",
  pkwOther = false,
  secondToolTip = "500 баллов, если будут использоваться свои деньги (полностью или частично)",
}: Props) => {
  const steps: Steps[] = (() => {
    switch (variant) {
      case "pkw": {
        return STEPS_PKW
      }
      case "productsCategories": {
        return STEPS_PRODUCTS_CATEGORIES
      }
      case "shuba": {
        return STEPS_SHUBA
      }
      case "zoon": {
        return STEPS_ZOON
      }
      case "inviteFriend": {
        return STEPS_INVITE_FRIEND
      }
      case "shapka": {
        return STEPS_SHAPKA
      }
      case "refin": {
        return STEPS_REFIN
      }
      case "refinKreditnoyKarty": {
        return STEPS_REFIN_KREDITNOY_KARTY
      }
      case "standard": {
        return DEFAULT_STEPS
      }
      case "redisignBezProcentov": {
        return STEPS_REDISIGN_BEZPROCENTOV
      }
      case "pkwRedesign": {
        return STEPS_PKW_REDISIGN
      }
      case "stickerPay": {
        return STEPS_STICKER_PAY
      }
      case "newYear": {
        return STEPS_NEWYEAR
      }
      case "kiberponedelnik": {
        return STEPS_KIBERPONEDELNIK
      }
      case "genderAction": {
        return STEPS_GENDER_ACTION
      }
      case "promopage": {
        return STEPS_PROMOPAGE
      }
      case "nedeliUmnuxRassrochek": {
        return STEPS_NEDELI_UMNUX_RASSROCHEK
      }
      case "nedeliUmnuxRassrochekMedia": {
        return STEPS_NEDELI_UMNUX_RASSROCHEK_MEDIA
      }
      case "lenta": {
        return STEPS_LENTA
      }
      case "pkwOzon": {
        return STEPS_PKW_OZON
      }
      case "pkwAviasales": {
        return STEPS_PKW_AVIASALES
      }
      case "halva": {
        return STEPS_HALVA
      }
      case "bonus1500": {
        return STEPS_PKW_BONUS_1500
      }
      case "cashback100": {
        return STEPS_100_CASHBACK
      }
      case "btsAuto": {
        return STEPS_BTS_AUTO
      }
      case "autoPromo": {
        return STEPS_AUTO_PROMO
      }
      case "vkEducation": {
        return STEPS_VK_EDUCATION
      }
      case "hippo": {
        return STEPS_HIPPO
      }
      case "blackFridayHalva": {
        return STEPS_BLACK_FRIDAY_HALVA
      }
      case "procentyVernetMagazin": {
        return STEPS_PROCENTY_VERNET_MAGAZIN
      }
      case "blackFriday": {
        return STEPS_BLACK_FRIDAY
      }
      case "newYearMedia": {
        return STEPS_NEWYEAR_MEDIA
      }
      case "newYearHalva": {
        return STEPS_NEWYEAR_HALVA
      }
      case "rassrochkaSHalvoi": {
        return STEPS_RASSROCHKA_S_HALVOI
      }
      case "media": {
        return STEPS_MEDIA
      }
      default: {
        return DEFAULT_STEPS
      }
    }
  })()

  const [openTooltip, setOpenTooltip] = useState(false)

  const handleTooltipClose = () => {
    setOpenTooltip(false)
  }

  const handleTooltipOpen = () => {
    setOpenTooltip(true)
  }

  return (
    <section className={clsx(styles.section, styles[variant])} data-exclude={orderNum}>
      <Container className={styles.container}>
        <h2 className={clsx(styles.title, styles[variant])}>{headTitle}</h2>
        <div className={styles.wrapper}>
          <ul className={clsx(styles.steps, styles[variant])}>
            {steps.map(({ title, subtitle, clickToolTip }, i) => (
              <li
                key={i}
                className={clsx(styles.step, styles[variant], { [styles.pkwOther]: pkwOther })}
              >
                <div
                  className={clsx(styles.stepText, styles[variant], {
                    [styles.pointer]: clickToolTip,
                  })}
                >
                  {clickToolTip ? (
                    <p className={clsx(styles.stepTitle, styles[variant])}>
                      <ClickAwayListener onClickAway={handleTooltipClose}>
                        <div>
                          <Tooltip
                            classes={{
                              tooltip: styles.tooltip,
                            }}
                            PopperProps={{
                              disablePortal: false,
                            }}
                            onClose={handleTooltipOpen}
                            open={openTooltip}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            title={
                              <>
                                <div className={styles.hintModal}>
                                  <ul>
                                    <li>
                                      1000 баллов, если все <br /> покупки будут за заемные <br />{" "}
                                      средства
                                    </li>
                                    <li>{secondToolTip}</li>
                                  </ul>
                                </div>
                                <div
                                  className={styles.close}
                                  onClick={handleTooltipClose}
                                  aria-hidden="true"
                                >
                                  <img src={close_icon} alt="close" />
                                </div>
                              </>
                            }
                            TransitionComponent={Zoom}
                          >
                            <div
                              className={styles.hintContainer}
                              onClick={handleTooltipOpen}
                              aria-hidden="true"
                            >
                              {title}
                            </div>
                          </Tooltip>
                        </div>
                      </ClickAwayListener>
                    </p>
                  ) : (
                    <p className={clsx(styles.stepTitle, styles[variant])}>{title}</p>
                  )}
                  <p
                    className={clsx(styles.stepSubtitle, styles[variant], {
                      [styles.pkwOther]: pkwOther,
                    })}
                  >
                    {subtitle}
                  </p>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </Container>
    </section>
  )
}
